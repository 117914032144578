import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Filtering from "../components/filtering";
import FeaturedPost from "../components/featured-post";

export const query = graphql`
  query NewsIndexQuery {
    news: allSanityNews(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          _type
          tags {
            value
          }
          path
          tileDescription
          _rawContent(resolveReferences: { maxDepth: 8 })
          id
          image {
            image {
              asset {
                _id
                altText
              }
            }
          }
          publishedDate
        }
      }
    }
    tags: allSanityNews {
      distinct(field: tags___value)
    }
    page: allSanityNewsPage(filter: { slug: { current: { eq: "news" } } }) {
      edges {
        node {
          title
          pageSEO {
            metaDescription
            metaTitle
            metaImage {
              asset {
                _id
                altText
              }
              hotspot {
                height
                width
                x
                y
              }
            }
          }
          accordionModule {
            _key
            _type
            heading
            items {
              heading
              _rawBody(resolveReferences: { maxDepth: 8 })
            }
            buttonText
            buttonLink {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
          }
          featuredArticle {
            title
            tileDescription
            slug {
              current
            }
            _type
            tags {
              value
            }
            path
            publishedDate
            image {
              image {
                asset {
                  altText
                  _id
                }
              }
            }
          }
        }
      }
    }
  }
`;

class News extends React.Component {
  render() {
    const news = this.props.data.news.edges;
    const tags = this.props.data.tags.distinct;
    const page = this.props.data.page.edges[0].node;

    return (
      <Layout className="news-index">
        <SEO title={page.title} seo={page.pageSEO} />
        <FeaturedPost
          featuredPost={
            page.featuredArticle ? page.featuredArticle : news[0].node
          }
        />
        <Filtering data={news} tags={tags} type="news" />
      </Layout>
    );
  }
}

export default News;
